/** @jsx jsx */
import { jsx } from "theme-ui"

import React from "react"
import { Link } from "gatsby"
import Children from "react-children-utilities"
import external from "../images/external.svg"

const Linkify = ({ children }) => {
  const isInternalLink = link => link.startsWith("/")

  const newChildren = Children.deepMap(children, function(child) {
    if (child.type === "a") {
      if (isInternalLink(child.props.href)) {
        return React.createElement(
          Link,
          {
            to: child.props.href,
          },
          child.props.children
        )
      } else if (!child.props.href.startsWith("mailto:")) {
        return React.createElement(
          "a",
          {
            href: child.props.href,
            target: "_blank",
            rel: "nofollower noopener",
          },
          child.props.children
        )
      } else {
        return child
      }
    } else {
      return child
    }
  })

  return <>{newChildren}</>
}

export default Linkify
