/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React from "react"
import showdown from "showdown"
import ReactHtmlParser from "react-html-parser"
import Linkify from "./linkify"
import { motion } from "framer-motion"

import transition from "../utils/framer-transition"

const textMotionVariants = {
  initial: { x: -60, opacity: 0 },
  enter: { x: 0, opacity: 1, transition },
  exit: {
    x: -60,
    opacity: 0,
    transition: { ...transition },
  },
}

const Copy = ({ markup, copySx, small }) => {
  const converter = new showdown.Converter()
  const HtmlContent = ReactHtmlParser(converter.makeHtml(markup))
  return (
    <motion.div
      variants={textMotionVariants}
      sx={{
        p: {
          fontSize: "0.85em",
          lineHeight: "1.75",
          fontWeight: "normal",
          marginBottom: "2rem",
          "&:last-child": {
            marginBottom: "0px",
          },
        },
        a: {
          variant: "text.link",
        },
        ul: {
          listStyle: "none",
          marginLeft: "0px",
          paddingLeft: "0px",
          marginBottom: 0,
          "&:last-child": {
            marginBottom: "0px",
          },
        },
        li: {
          display: "flex",
          marginBottom: 0,
          "&:last-child": {
            marginBottom: "0px",
          },
          "&:before": {
            content: "'—'",
            paddingRight: 0,
          },
        },
        code: {
          background: "#eaeaea",
          borderRadius: "2px",
          padding: "4px",
          fontSize: "0.7em",
          fontFamily:
            'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace',
        },
        ...copySx,
      }}
    >
      <Linkify>{HtmlContent}</Linkify>
    </motion.div>
  )
}

Copy.defaultProps = {
  small: false,
}

export default Copy
